export default [
  {
    name: 'Motorista',
    role: 'all',
    path: '/drivers',
    icon: 'tim-icons icon-bus-front-12'
  },
  {
    name: 'Clientes',
    role: 'all',
    path: '/clients',
    icon: 'tim-icons icon-single-02'
  },
  {
    name: 'Bairro',
    role: 'all',
    path: '/districts',
    icon: 'tim-icons icon-pin'
  },
  {
    name: 'Escolas',
    role: 'all',
    path: '/schools',
    icon: 'tim-icons icon-pencil'
  },
  {
    name: 'Publicidade',
    role: 'all',
    path: '/publicities',
    icon: 'tim-icons icon-bulb-63'
  }

  // {
  //   name: 'Administração',
  //   role: 'all',
  //   path: '/coming_soon',
  //   icon: 'tim-icons icon-lock-circle',
  //   children: [
  //     {
  //       name: 'Dados gerais',
  //       role: 'all',
  //       path: '/coming_soon'
  //     },
  //     {
  //       name: 'Usuários',
  //       role: 'all',
  //       path: '/coming_soon'
  //     },
  //     {
  //       name: 'Licença',
  //       role: 'all',
  //       path: '/coming_soon'
  //     }
  //   ]
  // }
]
