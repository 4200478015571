<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-fixed-toggle-button />
    <side-bar
      background-color="orange"
      :short-title="$t('sidebar.shortTitle')"
      :autoClose="false"
      :title="$t('sidebar.title')"
      logo="/img/logo.png"
    >
      <template v-if="user.status !== 3" slot="links">
        <template v-for="(item, index) in menu">
          <sidebar-item
            :link="{
              name: item.name,
              icon: item.icon,
              path: item.path || '#'
            }"
            :key="index"
            v-if="item.role === 'all' || item.role === user.role"
          >
            <template v-for="(child, children) in item.children">
              <sidebar-item
                :link="{
                  name: child.name,
                  icon: child.icon,
                  path: child.path
                }"
                :key="children"
                v-if="item.role === 'all' || item.role === user.role"
              >
              </sidebar-item>
            </template>
          </sidebar-item>
        </template>
      </template>
      <template v-if="user.status === 3">
        <p class="complete_register">
          Complete seu cadastro para ter acesso a todo o sistema.
        </p>
      </template>
    </side-bar>
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <!-- <sidebar-share  background-color.sync="white"> </sidebar-share> -->
    <div class="main-panel">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

import { mapState, mapGetters } from 'vuex'

import { ZoomCenterTransition } from 'vue2-transitions'
import DashboardNavbar from './DashboardNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import SidebarFixedToggleButton from './SidebarFixedToggleButton.vue'
import menu from '@/util/sidebarItems'

function hasElement (className) {
  return document.getElementsByClassName(className).length > 0
}

function initScrollbar (className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`)
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className)
    }, 100)
  }
}

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    ZoomCenterTransition
  },
  data () {
    return {
      menu: menu
    }
  },
  methods: {
    toggleSidebar () {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
    initScrollbar () {
      const docClasses = document.body.classList
      const isWindows = navigator.platform.startsWith('Win')
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar')
        initScrollbar('main-panel')
        initScrollbar('sidebar-wrapper')

        docClasses.add('perfect-scrollbar-on')
      } else {
        docClasses.add('perfect-scrollbar-off')
      }
    },
    getUserData () {
      if (!this.isUserLoad) {
        this.$jwt.getUserData()
      }
    }
  },
  computed: {
    ...mapGetters('auth', 'isUserLoad'),
    ...mapState('auth', ['user'])
  },
  mounted () {
    this.initScrollbar()
    this.getUserData()
  }
}
</script>

<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}

.complete_register {
  padding: 0.75rem;
  text-align: justify;
  font-weight: 600;
}

.sidebar-mini .sidebar .complete_register {
  opacity: 0;
}

.sidebar-mini .sidebar:hover .complete_register {
  opacity: 1;
  transition: 0.5s;
}
</style>
