var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":"orange","short-title":_vm.$t('sidebar.shortTitle'),"autoClose":false,"title":_vm.$t('sidebar.title'),"logo":"/img/logo.png"}},[(_vm.user.status !== 3)?_c('template',{slot:"links"},[_vm._l((_vm.menu),function(item,index){return [(item.role === 'all' || item.role === _vm.user.role)?_c('sidebar-item',{key:index,attrs:{"link":{
            name: item.name,
            icon: item.icon,
            path: item.path || '#'
          }}},[_vm._l((item.children),function(child,children){return [(item.role === 'all' || item.role === _vm.user.role)?_c('sidebar-item',{key:children,attrs:{"link":{
                name: child.name,
                icon: child.icon,
                path: child.path
              }}}):_vm._e()]})],2):_vm._e()]})],2):_vm._e(),(_vm.user.status === 3)?[_c('p',{staticClass:"complete_register"},[_vm._v(" Complete seu cadastro para ter acesso a todo o sistema. ")])]:_vm._e()],2),_c('div',{staticClass:"main-panel"},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }